<template>
  <div id="home-page">
    <!-- <v-main></v-main> -->
    <v-categoryes></v-categoryes>
    <v-cars-tabs></v-cars-tabs>
    <!-- <v-cars-filters></v-cars-filters> -->
    <br />
    <div class="container">
      <h2>Последние поступления</h2>
    </div>

    <v-cars-list></v-cars-list>
    <!-- <v-credit></v-credit> -->
    <v-banks></v-banks>
    <v-reviews></v-reviews>
    <v-contacts></v-contacts>
  </div>
</template>

<script>
// import vMain from '@/components/vMain';
import vCategoryes from '@/components/vCategoryes';
import vCarsList from '@/components/vCarsList';
import vCarsTabs from '@/components/vCarsTabs';
// import vCarsFilters from '@/components/vCarsFilters';
// import vCredit from '@/components/vCredit';
import vBanks from '@/components/vBanks';
import vReviews from '@/components/vReviews';
import vContacts from '@/components/vContacts';
export default {
  components: {
    // vMain,
    vCategoryes,
    vCarsList,
    // vCredit,
    vBanks,
    vReviews,
    // vCarsFilters,
    vCarsTabs,
    vContacts,
  },
};
</script>
