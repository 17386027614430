<template>
  <div id="home-page">
    <v-categoryes></v-categoryes>
    <v-cars-tabs></v-cars-tabs>
    <v-cars-filters></v-cars-filters>
    <v-cars-list :model="model"></v-cars-list>
    <v-banks></v-banks>
    <v-contacts></v-contacts>
  </div>
</template>

<script>
import vCategoryes from '@/components/vCategoryes';
import vCarsList from '@/components/vCarsList';
import vCarsTabs from '@/components/vCarsTabs';
import vCarsFilters from '@/components/vCarsFilters';
import vBanks from '@/components/vBanks';
import vContacts from '@/components/vContacts';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  components: {
    vCategoryes,
    vCarsTabs,
    vCarsList,
    vBanks,
    vCarsFilters,
    vContacts,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const model = route.params.model;
    const marks = computed(() => {
      if (route.path.indexOf('/cars/') !== -1) {
        return store.getters.MARKS_NEW;
      } else {
        return store.getters.MARKS;
      }
    });
    const body = computed(() => {
      if (route.path.indexOf('/cars/') !== -1) {
        return store.getters.BODY_NEW;
      } else {
        return store.getters.BODY;
      }
    });
    const bodys = store.getters.BODY_TRANSLATE;
    let bdy = bodys.find((item) => model === item.en.toLowerCase());
    if (
      marks.value.indexOf(model) === -1 &&
      bdy &&
      body.value.indexOf(bdy.ru) === -1
    )
      router.push('/404');
    return {
      model,
    };
  },
};
</script>
