<template>
  <header id="header" :class="mobileMenu ? '' : 'hide-mobile'">
    <div class="container">
      <div class="header-flex">
        <div class="header__address">
          <b>г. Санкт-Петербург</b><br />Стародеревенская ул., 11к2
        </div>
        <div class="header-logo-diler">
          <RouterLink to="/" @click.prevent="scrollTo('home-page')">
            <img
              :src="require(`@/assets/images/logo_car-hub-edit.jpg`)"
              alt="LADA"
              class="img-responsive"
            />
          </RouterLink>
        </div>

        <!-- <div class="header-contacts-info">
          <div class="subway">
            <img :src="require(`@/assets/images/subway-spb.svg`)" alt="метро" />
            <span>Старая Деревня</span>
          </div>
          <p><b>Стародеревенская ул., 11к2</b></p>
        </div> -->
        <div class="my-phone">
          <div class="header-phone">
            <v-phone></v-phone>
            <!-- <p>Ежедневно с 0{{ time_work_from }}:00 до {{ time_work_to }}:00</p> -->
            <p>
              <a href="#" @click.prevent="show = true">Заказать звонок</a>
            </p>
          </div>
          <!-- <div class="header-button">
            <a href="#" @click.prevent="show = true" class="header-btn"
              >Заказать звонок</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="header__nav">
      <div class="container">
        <ul>
          <li v-if="!hideLink">
            <RouterLink to="/cars/" @click.prevent="goTo('cars')"
              >Новое авто</RouterLink
            >
          </li>
          <li v-if="!hideLink">
            <RouterLink to="/used-cars/" @click.prevent="goTo('used-cars')"
              >Авто б/у</RouterLink
            >
          </li>
          <li v-if="!hideLink">
            <a href="#" @click.prevent="goTo('banks')">Кредит</a>
          </li>
          <li>
            <a href="#" @click.prevent="scrollTo('contacts')">Контакты</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <modal-callback v-model="show" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>Перезвоним через 15 сек.</template>
  </modal-callback>
</template>

<script>
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, ref } from "vue";
import vPhone from "@/components/vPhone";
import modalCallback from "@/components/modalCallback";
export default {
  components: { vPhone, modalCallback },
  props: ["mobile-menu"],
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const show = ref(false);
    const confirm = () => {
      show.value = false;
    };
    const cancel = (close) => {
      close();
    };
    const time_work_from = computed(() => store.getters.TIME_WORK_FROM);
    const time_work_to = computed(() => store.getters.TIME_WORK_TO);
    const hideLink = route.fullPath === "/all" ? true : false;
    window.addEventListener("scroll", () => {
      window.scrollY > 70
        ? document.querySelector("#header").classList.add("active")
        : document.querySelector("#header").classList.remove("active");
    });
    const scrollTo = (id) => {
      var element = document.getElementById(id);
      var headerOffset = 75;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };
    const goTo = (id) => {
      if (route.path !== "/") {
        router.push("/");
        setTimeout(() => scrollTo(id), 300);
      } else {
        scrollTo(id);
      }
    };
    return {
      show,
      confirm,
      cancel,
      time_work_from,
      time_work_to,
      scrollTo,
      goTo,
      hideLink,
    };
  },
};
</script>

<style lang="sass">
#header
	position: fixed
	left: 0
	top: 0
	width: 100%
	z-index: 20
	background-color: rgba(255,255,255,1)
	transition: .3s ease
	border-top: 4px solid $accent
	box-shadow: 0 0 48px -16px rgba(0, 0, 0, .32)
	&.active
		background-color: #fff
.subway
	display: flex
	align-items: flex-start
	justify-content: center
	img
		position: relative
		height: 20px
		margin-right: 5px
	span
		font-size: 14px
.header__address
	margin-right: auto
	margin-left: 48px
	font-size: 12px
	line-height: 1.2
	b
		font-size: 16px

.header__nav
	background-color: #fff
	width: 100%
	ul
		display: flex
		align-items: center
		justify-content: center
		list-style-type: none

		li
			a
				text-decoration: none
				display: block
				margin: 0px 5px
				padding: 15px 10px
				&:hover
					background-color: $accent
					color: #fff

.header-flex
	display: flex
	align-items: center
	justify-content: space-between
	padding: 10px 0
	position: relative
	overflow: hidden
.header-logo-diler
	position: absolute
	left: 50%
	transform: translate(-50%, -50%)
	top: 50%
	padding-top: 5px
	a
		display: block
		width: 150px
	p
		margin-left: 15px
		b

.header-contacts-info
	margin-right: 15px
	p
		font-size: 14px
		text-align: center
		font-weight: 400
		line-height: 1.2em
.my-phone
	display: flex
	align-items: center
	.header-phone
		margin-right: 30px
		span a
			font-size: 18px
			font-weight: 700
			text-decoration: none
		p
			font-size: 12px
.header-btn
	display: inline-block
	padding: 10px 33px
	color: #fff
	font-size: 14px
	font-weight: 400
	outline: none
	text-decoration: none
	background-color: $accent
	&:hover
		background-color: $black
		color: #fff
		text-decoration: none
.header-logo
	width: 108px
	background-color: #fff
	padding: 10px 10px 0px 10px
	margin-left: 90px
	position: relative
	top: 25px
@include sm-tablets
	.header-contacts-info
		display: none
	.header-logo-diler p
		display: none
	.header-logo,
	.header-button
		display: none
	.my-phone .header-phone
		margin-right: 0
	.header-logo-diler a
		width: 100px
</style>
