export const dataCars = [
  {
    VIN: "LBECNAFD5PZ3521671",
    availability: "В  наличии",
    body_type: "Хетчбэк",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Ford, Focus III",
    id: "59151261",
    imageLoad: require(`@/assets/images/cars/ford-focus/c9c388c58c75.jpg`),
    images: [
      require(`@/assets/images/cars/ford-focus/c9c388c58c75.jpg`),
      require(`@/assets/images/cars/ford-focus/1bd93c140355.jpg`),
      require(`@/assets/images/cars/ford-focus/f9067fe123a3.jpg`),
      require(`@/assets/images/cars/ford-focus/fdea0787fac0.jpg`),
      require(`@/assets/images/cars/ford-focus/43d55f7b7cda.jpg`),
      require(`@/assets/images/cars/ford-focus/56eda4403c3e.jpg`),
      require(`@/assets/images/cars/ford-focus/300f554209a2.jpg`),
      require(`@/assets/images/cars/ford-focus/17022e76e9c8.jpg`),
      require(`@/assets/images/cars/ford-focus/35754f70546f.jpg`),
      require(`@/assets/images/cars/ford-focus/a4d4bc4aa4d2.jpg`),
      require(`@/assets/images/cars/ford-focus/b7197c3baabc.jpg`),
      require(`@/assets/images/cars/ford-focus/d423386a9f3e.jpg`),
      require(`@/assets/images/cars/ford-focus/fee574394230.jpg`),
    ],
    kpp: "Робот",
    mark_id: "Ford",
    model: "Focus III",
    model_id: "focus III",
    modification_id: false,
    owners_number: "2",
    price: "479000",
    privod: "Передний",
    pts: false,
    run: "139900",
    url_id: "ford",
    year: "2012",
  },

  {
    VIN: "LBECNAFD5PZ3521672",
    availability: "В  наличии",
    body_type: "Хэтчбек",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Opel, Astra J Рестайлинг",
    id: "56690042",
    imageLoad: require(`@/assets/images/cars/opel-astra/00107645259d.jpg`),
    images: [
      require(`@/assets/images/cars/opel-astra/00107645259d.jpg`),
      require(`@/assets/images/cars/opel-astra/2ccd3c219999.jpg`),
      require(`@/assets/images/cars/opel-astra/304ae743e453.jpg`),
      require(`@/assets/images/cars/opel-astra/6838fc81646a.jpg`),
      require(`@/assets/images/cars/opel-astra/28d1b600dcff.jpg`),
      require(`@/assets/images/cars/opel-astra/65a2a2d31892.jpg`),
      require(`@/assets/images/cars/opel-astra/76c2e9e20791.jpg`),
      require(`@/assets/images/cars/opel-astra/88ce4d43dfa4.jpg`),
      require(`@/assets/images/cars/opel-astra/0139c76ab4f6.jpg`),
      require(`@/assets/images/cars/opel-astra/263b32b9596c.jpg`),
      require(`@/assets/images/cars/opel-astra/a551b49a93c5.jpg`),
      require(`@/assets/images/cars/opel-astra/cce5fe7e69d7.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Opel",
    model: "Astra J Рестайлинг",
    model_id: "astra",
    modification_id: false,
    owners_number: "2",
    price: "519000",
    privod: "Передний",
    pts: false,
    run: "111307",
    url_id: "opel",
    year: "2012",
  },
  {
    VIN: "LBECNAFD5PZ3521673",
    availability: "В  наличии",
    body_type: "Хэтчбек",
    color: "Бежевый",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Renault, Megane III Рестайлинг",
    id: "56690043",
    imageLoad: require(`@/assets/images/cars/renault-megane/a9f08087b96e.jpg`),
    images: [
      require(`@/assets/images/cars/renault-megane/a9f08087b96e.jpg`),
      require(`@/assets/images/cars/renault-megane/4bfab029ce44.jpg`),
      require(`@/assets/images/cars/renault-megane/13ac792005dc.jpg`),
      require(`@/assets/images/cars/renault-megane/cece0e9df5dd.jpg`),
      require(`@/assets/images/cars/renault-megane/0cf89cd587f7.jpg`),
      require(`@/assets/images/cars/renault-megane/5d29b2c92873.jpg`),
      require(`@/assets/images/cars/renault-megane/8cba27e20ecf.jpg`),
      require(`@/assets/images/cars/renault-megane/6435a92f807e.jpg`),
      require(`@/assets/images/cars/renault-megane/c5d241c3e5c8.jpg`),
      require(`@/assets/images/cars/renault-megane/d55d04edd7c7.jpg`),
      require(`@/assets/images/cars/renault-megane/eed807502138.jpg`),
    ],
    kpp: "Вариатор",
    mark_id: "Renault",
    model: "Megane III Рестайлинг",
    model_id: "megane",
    modification_id: false,
    owners_number: "1",
    price: "519000",
    privod: "Передний",
    pts: false,
    run: "128365",
    url_id: "renault",
    year: "2014",
  },
  {
    VIN: "LBECNAFD5PZ3521674",
    availability: "В  наличии",
    body_type: "Седан",
    color: "Белый",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Toyota, Corolla XI (E160, E170, E180)",
    id: "56690044",
    imageLoad: require(`@/assets/images/cars/toyota-corolla/c864a0b997d6.jpg`),
    images: [
      require(`@/assets/images/cars/toyota-corolla/c864a0b997d6.jpg`),
      require(`@/assets/images/cars/toyota-corolla/37ced50221c0.jpg`),
      require(`@/assets/images/cars/toyota-corolla/16178d9a2582.jpg`),
      require(`@/assets/images/cars/toyota-corolla/654543f6e35c.jpg`),
      require(`@/assets/images/cars/toyota-corolla/7bd9ea56aae7.jpg`),
      require(`@/assets/images/cars/toyota-corolla/7ce241a7c810.jpg`),
      require(`@/assets/images/cars/toyota-corolla/95e92377b3dd.jpg`),
      require(`@/assets/images/cars/toyota-corolla/a3db980bb9a5.jpg`),
      require(`@/assets/images/cars/toyota-corolla/af2936c32855.jpg`),
      require(`@/assets/images/cars/toyota-corolla/bcf7d1eac75c.jpg`),
      require(`@/assets/images/cars/toyota-corolla/d02fbf6a25e8.jpg`),
      require(`@/assets/images/cars/toyota-corolla/f5f5daedb074.jpg`),
      require(`@/assets/images/cars/toyota-corolla/fd16358713f3.jpg`),
    ],
    kpp: "Вариатор",
    mark_id: "Toyota",
    model: "Corolla XI (E160, E170, E180)",
    model_id: "corolla",
    modification_id: false,
    owners_number: "1",
    price: "889000",
    privod: "Передний",
    pts: false,
    run: "138880",
    url_id: "toyota",
    year: "2015",
  },
  {
    VIN: "LBECNAFD5PZ3521675",
    availability: "В  наличии",
    body_type: "Седан",
    color: "Белый",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Ford, Mondeo IV Рестайлинг",
    id: "56690045",
    imageLoad: require(`@/assets/images/cars/ford-mondeo/f7ff07ba522e.jpg`),
    images: [
      require(`@/assets/images/cars/ford-mondeo/f7ff07ba522e.jpg`),
      require(`@/assets/images/cars/ford-mondeo/9dce9d5c7d99.jpg`),
      require(`@/assets/images/cars/ford-mondeo/3863fd37eb93.jpg`),
      require(`@/assets/images/cars/ford-mondeo/9412f0bce968.jpg`),
      require(`@/assets/images/cars/ford-mondeo/1e81da1adbf0.jpg`),
      require(`@/assets/images/cars/ford-mondeo/06b4ba9a9a55.jpg`),
      require(`@/assets/images/cars/ford-mondeo/27bfc746b873.jpg`),
      require(`@/assets/images/cars/ford-mondeo/63d1cdf005bc.jpg`),
      require(`@/assets/images/cars/ford-mondeo/b7074ab3eeb2.jpg`),
      require(`@/assets/images/cars/ford-mondeo/bea15a0af280.jpg`),
      require(`@/assets/images/cars/ford-mondeo/c6b714e3d045.jpg`),
      require(`@/assets/images/cars/ford-mondeo/c7ed0ee97187.jpg`),
      require(`@/assets/images/cars/ford-mondeo/c82a7334b5ae.jpg`),
    ],
    kpp: "Робот",
    mark_id: "Ford",
    model: "Mondeo IV Рестайлин",
    model_id: "mondeo",
    modification_id: false,
    owners_number: "1",
    price: "629000",
    privod: "Передний",
    pts: false,
    run: "145006",
    url_id: "ford",
    year: "2012",
  },
  {
    VIN: "LBECNAFD5PZ3521676",
    availability: "В  наличии",
    body_type: "Хэтчбек",
    color: "Синий",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Renault, Sandero II",
    id: "56690046",
    imageLoad: require(`@/assets/images/cars/renault-sandero/62cfd9aa7b50.jpg`),
    images: [
      require(`@/assets/images/cars/renault-sandero/62cfd9aa7b50.jpg`),
      require(`@/assets/images/cars/renault-sandero/4afe5a20286e.jpg`),
      require(`@/assets/images/cars/renault-sandero/8b74683bf5ed.jpg`),
      require(`@/assets/images/cars/renault-sandero/31c25ef238a4.jpg`),
      require(`@/assets/images/cars/renault-sandero/521bc219f518.jpg`),
      require(`@/assets/images/cars/renault-sandero/692fd8301dd1.jpg`),
      require(`@/assets/images/cars/renault-sandero/4208df03ad4b.jpg`),
      require(`@/assets/images/cars/renault-sandero/7137170c958a.jpg`),
      require(`@/assets/images/cars/renault-sandero/805206854bf4.jpg`),
      require(`@/assets/images/cars/renault-sandero/a539fb429001.jpg`),
      require(`@/assets/images/cars/renault-sandero/ebda99545b7a.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Renault",
    model: "Sandero II",
    model_id: "sandero",
    modification_id: false,
    owners_number: "1",
    price: "649000",
    privod: "Передний",
    pts: false,
    run: "106453",
    url_id: "renault",
    year: "2016",
  },
  {
    VIN: "LBECNAFD5PZ3521677",
    availability: "В  наличии",
    body_type: "Внедорожник",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Volvo, XC90 I Рестайлинг",
    id: "56690047",
    imageLoad: require(`@/assets/images/cars/volvo-xc90/3e50ebef3391.jpg`),
    images: [
      require(`@/assets/images/cars/volvo-xc90/3e50ebef3391.jpg`),
      require(`@/assets/images/cars/volvo-xc90/36cb356039f3.jpg`),
      require(`@/assets/images/cars/volvo-xc90/6593c4dd89c8.jpg`),
      require(`@/assets/images/cars/volvo-xc90/0141035e1056.jpg`),
      require(`@/assets/images/cars/volvo-xc90/0b41b6620585.jpg`),
      require(`@/assets/images/cars/volvo-xc90/1c005bcd9a5c.jpg`),
      require(`@/assets/images/cars/volvo-xc90/7e0ab00a1a0c.jpg`),
      require(`@/assets/images/cars/volvo-xc90/69b36af18dbc.jpg`),
      require(`@/assets/images/cars/volvo-xc90/94e4ec9c56f0.jpg`),
      require(`@/assets/images/cars/volvo-xc90/178d96ec2303.jpg`),
      require(`@/assets/images/cars/volvo-xc90/3157c2b94c73.jpg`),
      require(`@/assets/images/cars/volvo-xc90/845818507e3b.jpg`),
      require(`@/assets/images/cars/volvo-xc90/ceb7dad53313.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Volvo",
    model: "XC90 I Рестайлинг",
    model_id: "xc90",
    modification_id: false,
    owners_number: "2",
    price: "709000",
    privod: "Полный",
    pts: false,
    run: "182300",
    url_id: "volvo",
    year: "2007",
  },
  {
    VIN: "LBECNAFD5PZ3521678",
    availability: "В  наличии",
    body_type: "Внедорожник",
    color: "Серый",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Nissan, X-Trail III Рестайлинг",
    id: "56690048",
    imageLoad: require(`@/assets/images/cars/nissan-x-trail/b95cbf641db3.jpg`),
    images: [
      require(`@/assets/images/cars/nissan-x-trail/b95cbf641db3.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/35a1beb90555.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/262dfad41b63.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/7433aad34265.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/0b31124c83ef.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/226bc1db672f.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/8834e7fbbefc.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/59431bc12f0c.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/ae132f9f097b.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/b434d0fa8e56.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/c84b8d8ac444.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/d253d86dfc5a.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/ef989149d61d.jpg`),
      require(`@/assets/images/cars/nissan-x-trail/fe6f88109c43.jpg`),
    ],
    kpp: "Вариатор",
    mark_id: "Nissan",
    model: "X-Trail III Рестайлинг",
    model_id: "x-trail",
    modification_id: false,
    owners_number: "1",
    price: "1699000",
    privod: "Полный",
    pts: false,
    run: "35000",
    url_id: "nissan",
    year: "2020",
  },
  {
    VIN: "LBECNAFD5PZ3521679",
    availability: "В  наличии",
    body_type: "Хэтчбек",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "BMW, 1 серия II (F20/F21)",
    id: "56690049",
    imageLoad: require(`@/assets/images/cars/bmw-1/2989ff76dc10.jpg`),
    images: [
      require(`@/assets/images/cars/bmw-1/2989ff76dc10.jpg`),
      require(`@/assets/images/cars/bmw-1/100baee233d9.jpg`),
      require(`@/assets/images/cars/bmw-1/c0bbe90db42e.jpg`),
      require(`@/assets/images/cars/bmw-1/e8615b2d5114.jpg`),
      require(`@/assets/images/cars/bmw-1/17b8187e0a10.jpg`),
      require(`@/assets/images/cars/bmw-1/73eebb010854.jpg`),
      require(`@/assets/images/cars/bmw-1/640aee1e69a2.jpg`),
      require(`@/assets/images/cars/bmw-1/182737b7954b.jpg`),
      require(`@/assets/images/cars/bmw-1/6245731919b1.jpg`),
      require(`@/assets/images/cars/bmw-1/bd69dfc4130e.jpg`),
      require(`@/assets/images/cars/bmw-1/c7ed7b3b6565.jpg`),
      require(`@/assets/images/cars/bmw-1/f5f6b71ecb53.jpg`),
      require(`@/assets/images/cars/bmw-1/f7f21c020d06.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "BMW",
    model: "1 серия II (F20/F21)",
    model_id: "1 серия",
    modification_id: false,
    owners_number: "2",
    price: "709000",
    privod: "Задний",
    pts: false,
    run: "149146",
    url_id: "bmw",
    year: "2013",
  },
  {
    VIN: "LBECNAFD5PZ35216710",
    availability: "В  наличии",
    body_type: "Внедорожник",
    color: "Черный",
    engine: "Дизель",
    engine_power: false,
    engine_value: false,
    folder_id: "Hyundai, Santa Fe II Рестайлинг",
    id: "566900410",
    imageLoad: require(`@/assets/images/cars/hyundai-santa-fe/9c6d70d2b31a.jpg`),
    images: [
      require(`@/assets/images/cars/hyundai-santa-fe/9c6d70d2b31a.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/1a5953ebc7a5.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/7a95137f2af9.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/8cfe6496ebc3.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/2a6f704dfbe5.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/4e2e8c656c8b.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/9c12f390cf79.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/72bae982d6c9.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/985f1085ee8f.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/766000174f60.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/ad159f33ad7d.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/d874ebc4b182.jpg`),
      require(`@/assets/images/cars/hyundai-santa-fe/fc43a62f5906.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Hyundai",
    model: "Santa Fe II Рестайлинг",
    model_id: "santa fe",
    modification_id: false,
    owners_number: "3",
    price: "875000",
    privod: "Полный",
    pts: false,
    run: "138979",
    url_id: "hyundai",
    year: "2011",
  },
  {
    VIN: "LBECNAFD5PZ35216711",
    availability: "В  наличии",
    body_type: "Седан",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Hyundai, Solaris II Рестайлинг",
    id: "566900411",
    imageLoad: require(`@/assets/images/cars/hyundai-solaris/64b04e58ac9f.jpg`),
    images: [
      require(`@/assets/images/cars/hyundai-solaris/64b04e58ac9f.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/5da87f544f31.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/bee76d335bd9.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/e5b7d133742e.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/40c23fdd5c73.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/70df8ef1bcb0.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/75cd818c974e.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/7989a329e940.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/710030de98fa.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/d53eeaceed9e.jpg`),
      require(`@/assets/images/cars/hyundai-solaris/ea880e4174b6.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Hyundai",
    model: "Solaris II Рестайлинг",
    model_id: "solaris",
    modification_id: false,
    owners_number: "1",
    price: "1029000",
    privod: "Передний",
    pts: false,
    run: "41419",
    url_id: "hyundai",
    year: "2021",
  },
  {
    VIN: "LBECNAFD5PZ35216712",
    availability: "В  наличии",
    body_type: "Лифтбек",
    color: "Белый",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Skoda, Rapid I Рестайлинг",
    id: "566900412",
    imageLoad: require(`@/assets/images/cars/skoda-rapid/3230e1a3ab6e.jpg`),
    images: [
      require(`@/assets/images/cars/skoda-rapid/3230e1a3ab6e.jpg`),
      require(`@/assets/images/cars/skoda-rapid/22be6ae2ebc6.jpg`),
      require(`@/assets/images/cars/skoda-rapid/d9b5d5eafc67.jpg`),
      require(`@/assets/images/cars/skoda-rapid/e0e8f0f3d0d4.jpg`),
      require(`@/assets/images/cars/skoda-rapid/e85bc2cb38a5.jpg`),
      require(`@/assets/images/cars/skoda-rapid/6f33bb4fc587.jpg`),
      require(`@/assets/images/cars/skoda-rapid/619dca32ed0c.jpg`),
      require(`@/assets/images/cars/skoda-rapid/11024e077a96.jpg`),
      require(`@/assets/images/cars/skoda-rapid/855244d9ed30.jpg`),
      require(`@/assets/images/cars/skoda-rapid/1384841ee655.jpg`),
      require(`@/assets/images/cars/skoda-rapid/b66171b65252.jpg`),
      require(`@/assets/images/cars/skoda-rapid/bb2d25808600.jpg`),
      require(`@/assets/images/cars/skoda-rapid/d16ee4d49abd.jpg`),
      require(`@/assets/images/cars/skoda-rapid/d920355a2abb.jpg`),
    ],
    kpp: "Робот",
    mark_id: "Skoda",
    model: "Rapid I Рестайлинг",
    model_id: "rapid",
    modification_id: false,
    owners_number: "1",
    price: "829000",
    privod: "Передний",
    pts: false,
    run: "83281",
    url_id: "skoda",
    year: "2017",
  },
  {
    VIN: "LBECNAFD5PZ35216713",
    availability: "В  наличии",
    body_type: "Седан",
    color: "Синий",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Volkswagen, Polo V Рестайлинг",
    id: "566900413",
    imageLoad: require(`@/assets/images/cars/volkswagen-polo/9820f280a5c4.jpg`),
    images: [
      require(`@/assets/images/cars/volkswagen-polo/9820f280a5c4.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/038e9ac40275.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/84fb5323a058.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/4649b453dd6d.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/1f902832a8fb.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/7fb880e462e0.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/1994e9575bbe.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/bd919add1355.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/c72d1d247147.jpg`),
      require(`@/assets/images/cars/volkswagen-polo/c8947b1803c4.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Volkswagen",
    model: "Polo V Рестайлинг",
    model_id: "polo",
    modification_id: false,
    owners_number: "1",
    price: "689000",
    privod: "Передний",
    pts: false,
    run: "57899",
    url_id: "volkswagen",
    year: "2019",
  },
  {
    VIN: "LBECNAFD5PZ35216714",
    availability: "В  наличии",
    body_type: "Универсал",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Kia, Ceed III",
    id: "566900414",
    imageLoad: require(`@/assets/images/cars/kia-ceed/5ca85f241808.jpg`),
    images: [
      require(`@/assets/images/cars/kia-ceed/5ca85f241808.jpg`),
      require(`@/assets/images/cars/kia-ceed/6c6f3f71124f.jpg`),
      require(`@/assets/images/cars/kia-ceed/042e13cac257.jpg`),
      require(`@/assets/images/cars/kia-ceed/b8c658ac55c0.jpg`),
      require(`@/assets/images/cars/kia-ceed/5a4c28ecaa48.jpg`),
      require(`@/assets/images/cars/kia-ceed/7fa8ac0d741e.jpg`),
      require(`@/assets/images/cars/kia-ceed/31e333bc6a3d.jpg`),
      require(`@/assets/images/cars/kia-ceed/65de927020dc.jpg`),
      require(`@/assets/images/cars/kia-ceed/75e6b2abf397.jpg`),
      require(`@/assets/images/cars/kia-ceed/84ac552dc0e7.jpg`),
      require(`@/assets/images/cars/kia-ceed/123c0a49ab5e.jpg`),
      require(`@/assets/images/cars/kia-ceed/535f2633db41.jpg`),
      require(`@/assets/images/cars/kia-ceed/6295bcc261e5.jpg`),
      require(`@/assets/images/cars/kia-ceed/d28000c1feb2.jpg`),
    ],
    kpp: "Автомат",
    mark_id: "Kia",
    model: "Ceed III",
    model_id: "ceed",
    modification_id: false,
    owners_number: "1",
    price: "999000",
    privod: "Передний",
    pts: false,
    run: "73571",
    url_id: "kia",
    year: "2018",
  },
  {
    VIN: "LBECNAFD5PZ35216715",
    availability: "В  наличии",
    body_type: "Внедорожник",
    color: "Черный",
    engine: "Бензин",
    engine_power: false,
    engine_value: false,
    folder_id: "Nissan, Murano II (Z51) Рестайлинг 2",
    id: "566900415",
    imageLoad: require(`@/assets/images/cars/nissan-murano/b74eb53b689e.jpg`),
    images: [
      require(`@/assets/images/cars/nissan-murano/b74eb53b689e.jpg`),
      require(`@/assets/images/cars/nissan-murano/f0d540544410.jpg`),
      require(`@/assets/images/cars/nissan-murano/f339673f6d8c.jpg`),
      require(`@/assets/images/cars/nissan-murano/444dbde9ddde.jpg`),
      require(`@/assets/images/cars/nissan-murano/6ed3808ae3e4.jpg`),
      require(`@/assets/images/cars/nissan-murano/9ea856ce1484.jpg`),
      require(`@/assets/images/cars/nissan-murano/659fcb030805.jpg`),
      require(`@/assets/images/cars/nissan-murano/9635e2df1565.jpg`),
      require(`@/assets/images/cars/nissan-murano/64591a00767a.jpg`),
      require(`@/assets/images/cars/nissan-murano/344850f89291.jpg`),
      require(`@/assets/images/cars/nissan-murano/71500444bd47.jpg`),
      require(`@/assets/images/cars/nissan-murano/cbf540790e0f.jpg`),
      require(`@/assets/images/cars/nissan-murano/d4ce769278e7.jpg`),
      require(`@/assets/images/cars/nissan-murano/ef48f9a027a0.jpg`),
    ],
    kpp: "Вариатор",
    mark_id: "Nissan",
    model: "Murano II (Z51) Рестайлинг 2",
    model_id: "murano",
    modification_id: false,
    owners_number: "1",
    price: "969000",
    privod: "Полный",
    pts: false,
    run: "129479",
    url_id: "nissan",
    year: "2012",
  },
];
