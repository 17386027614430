import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Cars from '../views/cars/index.vue';
import Model from '../views/cars/_model.vue';
import Car from '../views/car/index.vue';
import All from '../views/all.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/all',
    name: 'All',
    component: All,
  },
  {
    path: '/cars',
    name: 'Cars',
    component: Cars,
  },
  {
    path: '/cars/:model',
    name: 'Model',
    component: Model,
  },
  {
    path: '/cars/:model/:id',
    name: 'Car',
    component: Car,
  },
  {
    path: '/used-cars',
    name: 'UsedCars',
    component: Home,
  },
  {
    path: '/used-cars/:model',
    name: 'UsedCarsModel',
    component: Model,
  },
  { path: '/404', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '404' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
